import { render, staticRenderFns } from "./findSubmitWork.vue?vue&type=template&id=0924b1dd&scoped=true&"
import script from "./findSubmitWork.vue?vue&type=script&lang=js&"
export * from "./findSubmitWork.vue?vue&type=script&lang=js&"
import style0 from "./findSubmitWork.vue?vue&type=style&index=0&id=0924b1dd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0924b1dd",
  null
  
)

export default component.exports