<template>
  <div>
    <el-row class="dash-board">
      <el-col :span="17">
        <p class="in1">作业类型：</p>
        <el-select style="width: 170px" class="e-inps" placeholder="作业状态" v-model="type" filterable clearable>
          <el-option v-for="(item,index) in typeData"
                    :key="index"
                    :label="item.title"
                    :value="item.val"/>
        </el-select>
        <p class="in2">学员姓名:</p>
        <el-input class="in3" placeholder="输入学员姓名" v-model="stuName"></el-input>
        <p class="in4">作业描述:</p>
        <el-input class="in5" placeholder="输入作业描述" v-model="describe"></el-input>
      </el-col>
      <el-col :span="4" style="display: flex;align-items: center;margin-left: 10px;">
        <el-button type="primary" @click="getOne" v-permission="['correction:select']" class="">查询</el-button>
        <el-button type="warning" @click="reset" class="">重置</el-button>
        <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="学员姓名"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业类型"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span v-show="scope.row.type == 1 ">文件提交</span>
            <span v-show="scope.row.type == 2 ">文本提交</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业分数"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.workGrade }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业描述"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.describe }}</span>
          </template>
        </el-table-column>
         <el-table-column
          label="提交时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.workTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="批改时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.updateTime === null">-</span>
            <span v-else>{{ scope.row.updateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="scope.row.workGrade === '未批改'" v-permission="['correction:correction']" @click="assessment(scope.row)">批 改</el-button>
            <el-button size="mini" type="success" v-permission="['correction:see']" @click="seebt(scope.row)">查 看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
      title="批改作业打分"
      :visible.sync="centerDialogVisible"
      width="600"
      center>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="打分：" prop="wordGrade">
          <el-input style="width: 90%" placeholder="打分0~100分" maxlength="3" name="number" type="text" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.wordGrade"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="noAuth('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="作业详情"
      :data="details"
      :visible.sync="centerDialogVisibleTwo"
      center>
      <el-row type="flex" class="row-bg">
        <el-col :span="24" v-if="details.workUrl != null">
          <span>作业附件：<a :href="details.workUrl">点击下载</a></span>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" v-if="details.stuWork != null">
        <el-col :span="24"><span>作业内容：</span>
          <span>{{details.stuWork}}</span>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisibleTwo = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { adminfindSubmitWork, modifyWorkGrade } from '@/api/training.js'
  export default {
    name: 'task',
    data () {
      return {
        typeData: [
          { title: '已批改', val: 1 },
          { title: '未批改', val: 0 }
        ],
        type: null,
        describe: '',
        stuName: '',
        stuId: '',
        classId: '',
        className: '',
        tableData: [],
        details: {},
        centerDialogVisible: false,
        centerDialogVisibleTwo: false,
        form: {
          wordGrade: null
        },
        total: 0,
        pageNum: 1,
        pageSize: 10,
        rules: {
          wordGrade: [
            { required: true, message: '请输入分数', trigger: 'blur' },
            { min: 1, max: 3, message: '长度在 1 到 2 个字符', trigger: 'blur' }
          ]
        }
      }
    },
    mounted () {
      // 钩子函数
      this.getOne()
    },
    methods: {
      // 初始化列表数据
      getOne () {
        const query = {
          classId: this.$route.query.id,
          stuId: this.$route.query.stuId,
          type: this.type,
          stuName: this.stuName,
          describe: this.describe,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        console.log(query)
        adminfindSubmitWork(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows //  接收返回的数据
            this.total = resp.data.data.total // 查询总条数
          }
          console.log(this.tableData)
        })
      },
      // 重置
      reset() {
        this.type = ''
        this.describe = ''
        this.stuName = ''
      },
       // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      assessment (row) {
        console.log(row)
        this.centerDialogVisible = true
        this.id = row.id
      },
      // 打分
      noAuth (form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            const query = {
                id: this.id,
                workGrade: this.form.wordGrade
            }
            modifyWorkGrade(query).then(resp => {
                if (resp.data.code === '200') {
                  this.centerDialogVisible = false
                  this.$notify({
                    title: '打分成功',
                    type: 'success'
                  })
                  this.form.wordGrade = ''
                  this.getOne()
                } else {
                  this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
      // 查看
      seebt (row) {
        this.details = row
        this.centerDialogVisibleTwo = true
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 290px}
  .in3{width: 140px; position: absolute; top: 14px;left: 360px}
  .in4{font-size: 13px; position: absolute; top:10px; left: 520px}
  .in5{width: 140px; position: absolute; top: 14px;left: 590px}
  .inquire{ margin-right: 20px;}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-text{line-height: 24px;}
  a{ width: 110px; line-height: 28px; display: inline-block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  a:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
</style>
